import { LocationsPage } from '../../../components/locations-page';
import type { GetServerSidePropsContext } from 'next';
import { TransactionViewWrapper } from '../../../components/view-transactions/transactions-view-wrapper';
import { getServerSideClients, getServerSideSafeLanguage } from '@dx-ui/framework-react-query';
import { AppProvider } from '../../../providers/app-provider';
import {
  COMMON_NAMESPACES,
  FEATURE_TOGGLES,
  LOCATIONS_NAMESPACES,
  REACT_QUERY_CONFIG,
  SEARCH_NAMESPACES,
} from '../../../constants';
import {
  serverSideAllRegionsQuery,
  serverSideFeatureToggles_FeatureConfigsQuery,
} from '@dx-ui/queries-dx-shop-search-ui';
import type { HotelSummaryOptions_GeocodePageQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { getUrlParamsString } from '../../../utils';
import {
  getCustomHeaders,
  serverSideGeocodePageHotelSummaryData,
  serverSideProps,
} from '../../../utils/serverSideProps';
import { FilterProvider } from '../../../providers/filter-provider';

type LocationsPageProps = {
  pageData: HotelSummaryOptions_GeocodePageQuery['geocodePage'];
  path: string;
  isOneLink: boolean;
};

export default function Locations({ pageData, path, isOneLink }: LocationsPageProps) {
  // If bounds are available on hotelSummaryOptions (the expanded bounding box), then use it. Othwise use standard bounds.
  const bounds = pageData?.hotelSummaryOptions?.bounds || pageData?.match?.geometry?.bounds;

  const initialBounds = {
    south: bounds?.southwest.latitude || 80,
    west: bounds?.southwest.longitude || -160,
    north: bounds?.northeast.latitude || 80,
    east: bounds?.northeast.longitude || 160,
  };

  return (
    <FilterProvider>
      <AppProvider
        initialHotelSummaryOptionsData={pageData as HotelSummaryOptions_GeocodePageQuery}
        isOneLink={isOneLink}
      >
        <LocationsPage initialBounds={initialBounds} path={path} />
        <TransactionViewWrapper />
      </AppProvider>
    </FilterProvider>
  );
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { resolvedUrl, res, params } = context;
  const locale = getServerSideSafeLanguage(context);
  // For dynamic routing all the path slugs will be in a single array on ctx.query called slug. We joined them together with / in order to pass to core+
  const locationPath = (params?.slug as string[])?.join('/') || '';
  const path = ['locations', locationPath].join('/').replace(/\/+/g, '/');

  const customHeaders = getCustomHeaders(context);
  const { authClient, queryClient } = getServerSideClients({
    ...REACT_QUERY_CONFIG,
    customHeaders,
    response: res,
  });
  const serverSideRulesAndToggles = await serverSideFeatureToggles_FeatureConfigsQuery(
    queryClient,
    {
      names: FEATURE_TOGGLES,
    }
  ).catch((e) => {
    console.log(`Error in serverSideFeatureToggles_FeatureConfigsQuery: ${e}`); // eslint-disable-line no-console
    return null;
  });

  if (!serverSideRulesAndToggles) {
    return { notFound: true };
  }
  const hotelSummaryData = await serverSideGeocodePageHotelSummaryData({
    path,
    queryClient,
    locale,
    url: resolvedUrl,
  }).catch((e) => {
    console.log(`Error in serverSideGeocodePageHotelSummaryData: ${e}`); // eslint-disable-line no-console
    return null;
  });

  if (!hotelSummaryData) {
    return { notFound: true };
  }

  const pageData = hotelSummaryData?.geocodePage;

  // perform redirect if match is null and query is complete using canconical url
  if (!pageData?.match) {
    //first check if a valid canonical uri is returned. If not then something went wrong. 404
    if (!pageData?.location?.uri) {
      return { notFound: true };
    }
    const urlParams = getUrlParamsString(locale, resolvedUrl);
    return {
      redirect: {
        destination: `/${locale}/${pageData?.location?.uri}${urlParams}`,
        permanent: true,
      },
    };
  }

  return serverSideProps({
    authClient,
    context,
    queryClient,
    namespaces: [...COMMON_NAMESPACES, ...LOCATIONS_NAMESPACES, ...SEARCH_NAMESPACES],
    pageProps: {
      customHeaders,
      pageData,
      path,
    },
    getServerSideQueries({ queryClient }) {
      return [
        serverSideAllRegionsQuery(queryClient, { language: 'en' }).catch((e) => {
          console.log(`Error in serverSideAllRegionsQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
      ];
    },
  });
};
